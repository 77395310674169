@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .grid-cols-8 {
  grid-template-columns: repeat(8, 1fr);
}
.grid-cols-17 {
  grid-template-columns: repeat(17, 1fr);
}
.grid-cols-7 {
  grid-template-columns: repeat(7, 1fr);
} */
