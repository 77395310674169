@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
.layout {
  padding-left: 20rem;
  padding-right: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 97vh;
  /* position: relative;
  top: 50%;
  transform: translateY(-50%); */
}
.list-disc {
  list-style-type: disc;
}
.logo-div {
  height: 5rem;
  width: 4.2rem;
}
.logo {
  aspect-ratio: 0.85 / 1;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0 0 0/7%); */
  color: grey;
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
}
#overlay div {
  height: 100%;
  width: 100%;
  display: table;
}
#overlay p.text-spinner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.css-lzr3bi-LoadingOverlayWrapperBase {
  background-color: transparent !important;
  background-color: rgba(0 0 0 2) !important;
  color: rgba(0, 0, -9, 0.3) !important;
  transition: none !important;
}
.loading_overlay_wrapper {
  background-color: transparent !important;
  color: rgba(0, 0, -9, 0.3) !important;
  transition: none !important;
}
.loading_overlay_overlay {
  background-color: transparent !important;
  color: rgba(0, 0, -9, 0.3) !important;
  transition: none !important;
}
.loading_overlay_content {
  background-color: transparent !important;
  color: rgba(0, 0, -9, 0.3) !important;
  transition: none !important;
}
.loading_overlay_spinner {
  background-color: transparent !important;
  color: red !important;
}
.css-lzr3bi-LoadingOverlayWrapperBase svg circle {
  /* background-color: rgba(0 0 0/7%) !important; */
  stroke: #af1821 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
