.customized-square-box {
  position: relative;
  min-height: 5rem;
  /* min-width: 14rem; */
  border-radius: 20px;
  border-color: theme("colors.grey");
  border: 1px solid theme("colors.grey");
  padding: 0.7rem;
}
.customized-square-box img {
  text-align: center;
  justify-content: center;
}
.img-div {
  height: 5rem;
  width: 5rem;
}
.vertical-line {
  border-left: 1px solid #42494a;
}
.customized-square-box img.gp-img {
  aspect-ratio: 1.11 / 1;
}
.customized-square-box img.ccg-img {
  aspect-ratio: 1 / 1;
}
.customized-square-box img.phar-img {
  aspect-ratio: 1.03 / 1;
}
.small-circle {
  min-height: 1.8rem;
  min-width: 1.8rem;
  /* position: absolute;
  right: 10px; */
  transition: all 0.5s ease-in;
}
.customized-square-box .small-circle {
  position: relative;
}
/* .custom-graph-container {
  height: 400px;
} */
.custom-graph-container div {
  height: 100%;
}
/* //start click effect */
.outside-circle:before {
  content: "";
  background-color: theme("colors.grey");
  min-height: 1.8rem;
  min-width: 1.8rem;
  border-radius: 50%;
  margin-left: auto;
  position: absolute;
}
.outside-circle:focus {
  outline: 0;
  color: #fff;
}
.customized-square-box:focus-within > .outside-circle:before {
  animation: effect_dylan 2s ease-out;
}
.squarebox-option {
  height: 0.9rem;
  width: 0.9rem;
  border: 2px solid #42494a;
}
.squarebox-option .tick {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%;
}
.highcharts-container {
  width: 100% !important;
}

.highcharts-root {
  width: 100% !important;
}
@keyframes effect_dylan {
  50% {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}

.submit-button {
  min-height: 3rem;
  min-width: 10rem;
  color: white;
}
.bar-line {
  min-height: 0.9rem;
  min-width: 1.8rem;
}
select {
  background-image: url("../../assets/down_arrow.png");
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background: transparent; */
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
.footer-text {
  font-size: 0.8rem;
  text-wrap: nowrap;
}
p.leading-6 {
  font-size: 0.9rem;
}
p.leading-5 {
  font-size: 0.8rem;
}
.form-page-text {
  color: #42494a;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
